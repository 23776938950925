
export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    image: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    ctaText: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: ''
    },
    imageCover: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    modalContent() {
      return {
        image: this.image || this.blok.image?.filename,
        heading: this.heading || this.blok.heading,
        description: this.description || this.blok.description,
        ctaText: this.ctaText || this.blok.cta_text,
        ctaLink: this.ctaLink || this.blok.cta_link?.cached_url
      }
    }
  },
  mounted() {
    // Retrieve source cookie to determine if we're coming from app
    const cookies = document.cookie
      .split('; ')
      .reduce((acc, cookie) => {
        const [key, value] = cookie.split('=')
        acc[key] = value
        return acc
      }, {})
    if ((this.blok.open || this.open) && !(cookies.source == 'app')) {
      const opened = this.$cookiz.get("promo-opened");
      this.showModal = "opened" !== opened;

      this.$nextTick(() => {
        const promoModal = this.$refs.promoModal;

        if (promoModal) {
          promoModal.focus()
        }

        document.addEventListener('keydown', this.trapFocus);

        // need to scroll top due to the focus behavior
        window.scrollTo(0, 0);
      })
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.trapFocus);
  },
  methods: {
    close() {
      this.$cookiz.set("promo-opened", "opened");
      this.showModal = false;
    },
    trapFocus(e) {
      if (!this.showModal) return;

      const focusableElements = this.$refs.promoModal.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (e.key === 'Tab') {
        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableElement) {
            e.preventDefault();
            lastFocusableElement.focus();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
          }
        }
      }

      if (e.key === 'Escape') {
        this.close();
      }
    },
  }
}
